import { createTheme, ThemeProvider } from '@mui/material/styles';
import { AppBar, Toolbar} from '@mui/material';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import {useNavigate } from 'react-router-dom';


function NavBar() {
   const navigate = useNavigate();


    const theme = createTheme({
      palette: {
        mode: 'light', // Set the theme mode to dark
      },
  
      typography: {
        menu: {
          fontFamily: 'Arial', // Change the font family for menu items
        },
      },
    
    });

    var pages;

    if(document.cookie !== "") {
      pages = ['Select', 'Diseases', 'Findings', 'Profile', 'Comments/Questions', 'Sign Out']
    }
    else {
      console.log(document.cookie)
      pages = ['Select', 'Diseases', 'Findings', 'Login', 'Register', 'Comments/Questions']

    }
  
    const renderPages = ( page) => {


      if(page == "Select") {
        navigate('/')
      }

      else if(page == "Register") {
        navigate('/register')
      }

      else if(page == "Login") {
        navigate('/login')
      }

      else if(page == "Profile") {
        navigate('/profile')
      }

      else if(page == "Diseases") {
        navigate('/disease')
      }

      else if(page == "Findings") {
        navigate('/finding')
      }

      else if(page == "Comments/Questions") {
        navigate('/comments')
      }


      else if(page == "Sign Out") {
        var currentDate = new Date();

        var expires = currentDate.toUTCString();

        document.cookie += `;expires==${expires}`
        navigate("/")
      }

    }
  
  
    return (
        <ThemeProvider theme={theme}>
          <div>
            <AppBar position="static">
              <Toolbar>
                <Typography variant="menu">Clinical Phenomics</Typography>
  
                {pages.map((page) => (
                  <MenuItem key={page} onClick={() => renderPages(page)}>
                    <Typography varient="menu" textAlign="center">{page}</Typography>
                  </MenuItem>
                ))}
  
              </Toolbar>
            </AppBar>
          </div>
        </ThemeProvider>
    )
  }

  export default NavBar;