import NavBar from "../../components/NavBar/NavBar"
import { TextField } from "@mui/material";
import Button from '@mui/material/Button';
import React from 'react';
import axios from "axios";
import {useNavigate } from 'react-router-dom';

function CommentsPage() {
    const [name, setName] = React.useState("")
    const [email, setEmail] = React.useState("")
    const [comment, setComment] = React.useState("")

    const navigate = useNavigate();


    const handleName = (event) => {
        setName(event.target.value)
    }

    const handleEmail = (event) => {
        setEmail(event.target.value)
    }

    const handleComment = (event) => {
        setComment(event.target.value)
    }

    const sendFeedback = async () => {
        if(comment == "") {
            alert("Please enter a comment or question")
            return
        }

        const payload = {
            name: name,
            email: email,
            comment: comment
        }

        const response = await axios.post("https://api.clinicalphenomics.org/feedback", payload)

        

        navigate("/")
        alert("Thanks for sharing your feedback!")


    }

    return (
        <div>
        <NavBar />
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'  }}>
        <h1 style={{ fontSize: '2rem'  }}>Comments/Questions</h1>
        <p> Please submit any comments or questions regarding the site below, and add your email address if you would like a response</p>

        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
        <label htmlFor="name" style={{ width: '200px', marginRight: '10px' }}> Name (optional):</label>
        <TextField id="name" value={name} onChange={handleName} />
        </div>

        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
        <label htmlFor="email" style={{ width: '200px', marginRight: '10px' }}> Email Address (optional):</label>
        <TextField id="email" value={email} onChange={handleEmail}/>
        </div>

        <div style={{ display: 'flex', flexDirection: 'column', alignItems: "flex-start", width:"500px", marginBottom: '10px', marginLeft:'61px' }}>
        <label htmlFor="comments" style={{marginBottom: "10px"}}> Please place your questions/comments below:</label>
        <TextField id="comments"  value={comment} onChange={handleComment} multiline rows={8} fullWidth />
        </div>




        <Button variant="contained" style={{marginBottom: '30px',  }} onClick={sendFeedback}> Submit </ Button> 


        </div>

        </div>
    )


}

export default CommentsPage;