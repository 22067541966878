import React from 'react';
import BasicAccordion from "./components/BasicAccordion/BasicAccordion"
import BoxSx from "./components/BoxSx/BoxSx"
import ExpirienceModal from "./components/ExpirienceModal/ExpirienceModal"
import NavBar from "../../components/NavBar/NavBar"
import NestedModal from "./components/NestedModal/NestedModal"
import SearchBar from "./components/SearchBar/SearchBar"
import axios from 'axios';
import filter from 'jade/lib/filters';

function SelectPage({selectedEntity, setSelectedEntity, filterSelect, setFilterSelect}) {

  const [onUpdate, setOnUpdate] = React.useState(false)
  const [onUpdateV2, setOnUpdateV2] = React.useState(false)


  return (    
  <div> 
  <NavBar />  

  <div style={{ position: 'absolute', top: '27vh', left: '50vw',  transform: 'translate(-50%, -50%)'  }}>
    <p> Welcome to Clinical Phenomics! Please select diseases below to view associated findings or findings to view associated diseases (along with references, images, comments, and statistics). Information about selected diseases can be viewed through the "Diseases" tab (above) or information about selected findings in the "Findings" tab. For example, findings useful for differentiating selected diseases can be identified in the "Diseases" tab, while diseases that display specific findings or combinations of findings can be viewed in the "Findings" tab. Please create an account if you would like to contribute information or images to the database!
  
    </p>

  </div>

  <div style={{ position: 'absolute', top: '52vh', left: '9vw',  transform: 'translate(-50%, -50%)'  }}>
  <NestedModal type="disease" onUpdate={onUpdate} setOnUpdate={setOnUpdate}/>

  </div>

  <div style={{ position: 'absolute', top: '52vh', left: '19vw',  transform: 'translate(-50%, -50%)'  }}>
  <SearchBar label="Search for a Disease" type="disease" selected={selectedEntity} setSelected={setSelectedEntity} filtered={filterSelect} setFiltered={setFilterSelect}/>
  </div>

  <div style={{ position: 'absolute', top: '42vh', left: '78vw',  transform: 'translate(-50%, -50%)'  }}>
    <h2> Search finding by name</h2>
  </div>

  <div style={{ position: 'absolute', top: '42vh', left: '13vw',  transform: 'translate(-50%, -50%)'  }}>
    <h2> Search disease by name</h2>
  </div>

  <div style={{ position: 'absolute', top: '61vh', left: '12vw',  transform: 'translate(-50%, -50%)'  }}>
    <h2> Search by hierarchy</h2>
  </div>

  <div style={{ position: 'absolute', top: '61vh', left: '77vw',  transform: 'translate(-50%, -50%)'  }}>
    <h2> Search by hierarchy</h2>
  </div>


  <div style={{ position: 'absolute', top: '61vh', left: '50vw',  transform: 'translate(-50%, -50%)'  }}>
    <h2> View selected diseases/findings</h2>
  </div>

  <div style={{ position: 'absolute', top: '52vh', left: '74vw',  transform: 'translate(-50%, -50%)'  }}>
  <NestedModal type="finding"   onUpdate={onUpdate} setOnUpdate={setOnUpdate}/>
  </div>

  <div style={{ position: 'absolute', top: '52vh', left: '50vw',  transform: 'translate(-50%, -50%)'  }}>
  <ExpirienceModal selected={selectedEntity} setFilterSelect={setFilterSelect}/>
  </div>
  <div style={{ position: 'absolute', top: '52vh', left: '85vw',  transform: 'translate(-50%, -50%)'  }}>
  <SearchBar label="Search for a Finding"  type="finding" selected={selectedEntity} setSelected={setSelectedEntity} filtered={filterSelect} setFiltered={setFilterSelect}/>
  </div>

  <div style={{ position: 'absolute', top: '85vh', left: '50vw',  transform: 'translate(-50%, -50%)'  }}>
  <BoxSx allSelected={selectedEntity} setAllSelected={setSelectedEntity} selected={filterSelect} setSelected={setFilterSelect} onUpdate={onUpdate} setOnUpdate={setOnUpdate}/>
  </div>
 
      <div style={{ position: 'absolute', top: '65vh', left: '7vw' }}>
            < BasicAccordion width='20vw' widthBox='22vw' filSelected={filterSelect} setFilSelect={setFilterSelect} selected={selectedEntity} setSelected={setSelectedEntity} type="disease" onUpdate={onUpdate}/>
      </div>

       <div style={{ position: 'absolute', top: '65vh', left: '72vw'}}>
           < BasicAccordion width='20vw' widthBox='22vw' filSelected={filterSelect} setFilSelect={setFilterSelect} selected={selectedEntity} setSelected={setSelectedEntity} type="finding" onUpdate={onUpdate}/>
       </div>


</div>

  )
}



export default SelectPage;
